import React, { useContext, useState } from "react";
import styled from "styled-components";
import { HugeText, MediumText } from "../../../components/share/StyledText";
import GoBack from "../../../components/share/GoBack";
import FlexBox from "../../../components/share/FlexBox";
import ProfileImage from "../../../components/share/ProfileImage";
import callIcon from "../../../assets/icons/project/callIcon.svg";
import { engGroupChanger, groupChanger } from "../../../lib/ProfileUtils";
import { ThemeContext } from "../../../context/ThemeProvider";
import CustomSelect from "../../../components/share/CustomSelect";
import { useMutation } from "@apollo/client";
import { CHANGE_SIGN_LINE } from "../../../graphql/Project/EditMembers/mutation";

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 20px 252px;
`;
const Header = styled.div`
  padding: 20px 0;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bgColor};

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 40px);
    height: 1px;
    left: -20px;
    bottom: 0;
    background-color: ${({ theme }) => theme.borderColor};
  }
`;
const ApprovalList = styled.div`
  margin: 26px 0 0;
`;
const ApprovalCard = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};

  &:last-child {
    margin: 0;
  }
`;
const ApprovalStamp = styled.div`
  min-width: 50px;
  padding: 2px 6px 0;
  height: 32px;
  color: ${({ status, theme }) =>
    status === "반려"
      ? theme.negativeColor
      : status === "승인"
      ? theme.activeColor
      : theme.borderColor};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ status, theme }) =>
      status === "반려"
        ? theme.negativeColor
        : status === "승인"
        ? theme.activeColor
        : theme.borderColor};
`;
const Icon = styled.img`
  align-self: flex-end;
`;
const ApprovalBox = styled(FlexBox)`
  width: ${({ width }) => (width ? width : "100%")};
  height: 76px;
  justify-content: space-between;
`;
const InfoBox = styled.div`
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
const GroupText = styled(MediumText)`
  max-width: 16vw;
  min-width: 52px;
  margin-right: 10px;
  color: ${({ theme }) => theme.subTextColor};
`;
const UserName = styled(MediumText)`
  width: 25vw;
  flex: 3;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RankText = styled(MediumText)``;
const CirculatorBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 16px 10px;
`;
const Circulator = styled.div`
  display: inline-block;
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;
const ChangeButton = styled.button.attrs(() => ({
  type: "button",
}))`
  width: 100%;
  padding: 6px 12px;
  margin-top: 16px;
  font-size: 14px;
  font-family: "AppleSDGothicNeoBold", "Apple SD Gothic Neo", sans-serif;
  color: ${({ theme, color }) =>
    color === "danger" ? theme.negativeColor : theme.activeColor};
  border-radius: 10px;
  border: 1px solid
    ${({ theme, color }) =>
      color === "danger" ? theme.negativeColor : theme.activeColor};

  &:active {
    color: ${({ theme }) => theme.whiteColor};
    opacity: 0.3;
    background-color: ${({ theme }) => theme.activeColor};
  }
`;

const ApprovalLine = ({
  title,
  rejectStatus,
  approvalStatus,
  image,
  group,
  name,
  rank,
  countryCode,
  cellPhone,
  email,
  activate,
  sl_id,
  signLine_type,
  changeSignLine,
  memberList = [],
  isOwnScar = false,
  scarStatus
}) => {
  const { language } = useContext(ThemeContext);
  const editMode = false;
  const [target, setTarget] = useState(null);
  console.log(scarStatus)
  const [changeSignLineApi] = useMutation(CHANGE_SIGN_LINE);

  const handleChangeButton = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        const { data } = await changeSignLineApi({
          variables: {
            sl_id,
            user_id: target.id,
            signLine_type,
          },
        });

        if (data.changeSignLine.result) {
          changeSignLine(signLine_type, target);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      //setEditMode((prev) => !prev);
    }
  };

  return (
    <ApprovalCard>
      <FlexBox justifyContent="space-between">
        <MediumText>{title}</MediumText>
        <ApprovalStamp
          status={rejectStatus ? "반려" : approvalStatus ? "승인" : "미승인"}
        >
          {rejectStatus
            ? (language === "Kor" && "반려") || (language === "Eng" && "Reject")
            : approvalStatus
            ? (language === "Kor" && "승인") || (language === "Eng" && "Aprv")
            : (language === "Kor" && "미승인") ||
              (language === "Eng" && "Unaprv")}
        </ApprovalStamp>
      </FlexBox>
      {editMode ? (
        <CustomSelect
          options={memberList}
          value={target}
          onChange={(name, op) => {
            setTarget({
              id: op.user_id,
              group: op.user_changedPermission,
              name: op.user_name,
              rank: op.user_rank,
              countryCode: op.user_countryCode,
              cellphone: op.user_cellphone,
              email: op.user_email,
              img: op.user_profileImg,
            });
          }}
        />
      ) : (
        <FlexBox justifyContent="space-between">
          <ProfileImage src={image} />
          <ApprovalBox width="calc(100% - 92px)">
            <InfoBox>
              <FlexBox alignItems="flex-end">
                <GroupText>
                  {(language === "Kor" && groupChanger(group)) ||
                    (language === "Eng" && engGroupChanger(group))}
                </GroupText>
                <FlexBox>
                  <UserName>
                    {name}
                    {/* {activate
                      ? ""
                      : ` (${
                          language === "Kor" ? "탈퇴회원" : "Withdrawal member"
                        })`} */}
                  </UserName>
                  &nbsp;
                  <RankText>{rank}</RankText>
                </FlexBox>
              </FlexBox>
              <FlexBox flexDirection="column">
                <MediumText margin="12px 0 4px">
                  {countryCode} {cellPhone}
                </MediumText>
                <MediumText>{email}</MediumText>
              </FlexBox>
            </InfoBox>
            {activate ? (
              <Icon
                src={callIcon}
                onClick={() => (document.location.href = `tel:${cellPhone}`)}
              />
            ) : null}
          </ApprovalBox>
        </FlexBox>
      )}

      {!activate && isOwnScar && scarStatus !== 'complete' ? (
        editMode ? (
          <FlexBox gap={6}>
            <ChangeButton color="danger" onClick={() => console.log(1)}>
              {language === "Kor" ? "취소" : "Cancel"}
            </ChangeButton>
            <ChangeButton onClick={handleChangeButton}>
              {language === "Kor" ? "확인" : "Done"}
            </ChangeButton>
          </FlexBox>
        ) : (
          <ChangeButton onClick={handleChangeButton}>
            {language === "Kor" ? "변경" : "Change"}
          </ChangeButton>
        )
      ) : ''}
    </ApprovalCard>
  );
};

const ApprovalProcessView = ({
  signLine,
  memberList = [],
  changeSignLine,
  language,
  isOwnScar = false,
  scarStatus
}) => {
  return (
    <Wrapper>
      <Header>
        <GoBack route={"/scar-detail"} />
        <HugeText>
          {language === "Kor" && "결재라인보기"}
          {language === "Eng" && "Viewing Approval Process"}
        </HugeText>
      </Header>

      <ApprovalList>
        <ApprovalLine
          title={
            (language === "Kor" && "발행자") ||
            (language === "Eng" && "HSE Officer (PM)")
          }
          rejectStatus={signLine?.create_rejectStatus}
          approvalStatus={signLine?.create_status}
          image={signLine?.create_img}
          group={signLine?.create_group}
          name={signLine?.create_name}
          rank={signLine?.create_rank}
          countryCode={signLine?.create_countryCode}
          cellPhone={signLine?.create_cellphone}
          email={signLine?.create_email}
          activate={signLine?.create_activate}
          sl_id={signLine?.sl_id}
          signLine_type="create"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
        <ApprovalLine
          title={
            (language === "Kor" && "발행확인자") ||
            (language === "Eng" && "Resident Engineer (PM)")
          }
          rejectStatus={signLine?.check_rejectStatus}
          approvalStatus={signLine?.check_status}
          image={signLine?.check_img}
          group={signLine?.check_group}
          name={signLine?.check_name}
          rank={signLine?.check_rank}
          countryCode={signLine?.check_countryCode}
          cellPhone={signLine?.check_cellphone}
          email={signLine?.check_email}
          activate={signLine?.check_activate}
          sl_id={signLine?.sl_id}
          signLine_type="check"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
        <ApprovalLine
          title={
            (language === "Kor" && "조치자") ||
            (language === "Eng" && "Safety Officer")
          }
          rejectStatus={signLine?.action_rejectStatus}
          approvalStatus={signLine?.action_status}
          image={signLine?.action_img}
          group={signLine?.action_group}
          name={signLine?.action_name}
          rank={signLine?.action_rank}
          countryCode={signLine?.action_countryCode}
          cellPhone={signLine?.action_cellphone}
          email={signLine?.action_email}
          sl_id={signLine?.sl_id}
          signLine_type="action"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
        <ApprovalLine
          title={
            (language === "Kor" && "책임자") ||
            (language === "Eng" && "Manager")
          }
          rejectStatus={signLine?.manager_rejectStatus}
          approvalStatus={signLine?.manager_status}
          image={signLine?.manager_img}
          group={signLine?.manager_group}
          name={signLine?.manager_name}
          rank={signLine?.manager_rank}
          countryCode={signLine?.manager_countryCode}
          cellPhone={signLine?.manager_cellphone}
          email={signLine?.manager_email}
          activate={signLine?.manager_activate}
          sl_id={signLine?.sl_id}
          signLine_type="manager"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
        <ApprovalLine
          title={
            (language === "Kor" && "조치확인자") ||
            (language === "Eng" && "Safety Checker")
          }
          rejectStatus={signLine?.actionCheck_rejectStatus}
          approvalStatus={signLine?.actionCheck_status}
          image={signLine?.actionCheck_img}
          group={signLine?.actionCheck_group}
          name={signLine?.actionCheck_name}
          rank={signLine?.actionCheck_rank}
          countryCode={signLine?.actionCheck_countryCode}
          cellPhone={signLine?.actionCheck_cellphone}
          email={signLine?.actionCheck_email}
          activate={signLine?.actionCheck_activate}
          sl_id={signLine?.sl_id}
          signLine_type="actionCheck"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
        <ApprovalLine
          title={
            (language === "Kor" && "최종승인자") ||
            (language === "Eng" && "Final Approver")
          }
          rejectStatus={signLine?.finalSign_rejectStatus}
          approvalStatus={signLine?.finalSign_status}
          image={signLine?.finalSign_img}
          group={signLine?.finalSign_group}
          name={signLine?.finalSign_name}
          rank={signLine?.finalSign_rank}
          countryCode={signLine?.finalSign_countryCode}
          cellPhone={signLine?.finalSign_cellphone}
          email={signLine?.finalSign_email}
          activate={signLine?.finalSign_activate}
          sl_id={signLine?.sl_id}
          signLine_type="finalSign"
          changeSignLine={changeSignLine}
          memberList={memberList}
          isOwnScar={isOwnScar}
          scarStatus={scarStatus}
        />
      </ApprovalList>

      <MediumText margin="24px 0 16px">
        {language === "Kor" && `회람자 (총 ${signLine?.circulators.length}명)`}
        {language === "Eng" &&
          `Distribution (total ${signLine?.circulators.length})`}
      </MediumText>
      <CirculatorBox>
        {signLine?.circulators?.map((circulator, index) => (
          <Circulator key={index}>
            {circulator?.circ_cellphone} {circulator?.circ_email}
          </Circulator>
        ))}
      </CirculatorBox>
    </Wrapper>
  );
};

export default React.memo(ApprovalProcessView);

import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export default function DeepLink(){

    const {pathname} = useLocation();

    useEffect(() =>{
        if(pathname){
            const scarId = pathname.replaceAll('/deep', '')
            if(scarId != '' && scarId != null){
                window.location.href='com.hanmi.riskzero.dev://scar-detail'+ scarId;
            }else{
                window.location.href='com.hanmi.riskzero.dev://';
            }
            
        }
    },[pathname])

    return(
        <></>
    )

}

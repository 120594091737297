import React, { useCallback, useContext, useEffect, useState } from "react";
import ScarSearchPresenter from "./ScarSearchPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { SEARCH_SCAR } from "../../../graphql/Project/ScarSearch/query";
import { useQuery } from "@apollo/client";
import { SEE_SCAR_LABEL_LIST } from "../../../graphql/Project/CreateScar/query";
import {useLocation} from "react-router-dom";

const ScarSearchContainer = () => {
  const {state} = useLocation();
  const { language } = useContext(ThemeContext);
  const prjId = Number(localStorage.getItem("prjId"));
  const now = new Date();
  const searchStartDate = new Date(2019, 0, 1);
  const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);

  const [scarList, setScarList] = useState([]);
  const [detailSort, setDetailSort] = useState(false); // 정렬 클릭 시 상세 정렬 모드
  const [labelList, setLabelList] = useState([]);
  const [sortType, setSortType] = useState({
    // 정렬 상태
    searchTerm: "",
    period: [searchStartDate, searchEndDate], // 기간 설정(기본 한달전 ~ 오늘까지 설정)
    label: "전체", // 라벨 상태
  });

  const {
    data: labelData,
    loading: labelLoading,
    refetch: labelRefetch,
  } = useQuery(SEE_SCAR_LABEL_LIST, {
    variables: {
      lb_language: (language || "").toLowerCase(),
    },
  });

  const {
    data: scarData,
    loading: scarLoading,
    refetch: scarRefetch,
  } = useQuery(SEARCH_SCAR, {
    variables: {
      prjId: prjId,
      searchTerm: sortType.searchTerm,
      start: sortType.period[0],
      end: sortType.period[1],
      label: sortType.label === "전체" ? "total" : sortType.label,
    },
  });

  const handleSortType = useCallback(
    (name, value) => {
      // 정렬 상태 변경 함수
      setSortType({
        ...sortType,
        [name]: value,
      });
    },
    [sortType]
  );

  const handleSortRefresh = useCallback(() => {
    // 정렬 상태 초기화
    setSortType({
      searchTerm: "",
      period: [searchStartDate, new Date()],
      label: "전체",
    });
  }, []);

  useEffect(() => {
    if (scarData?.searchScar?.result) {
      setScarList(scarData.searchScar.scarList);
    }
  }, [scarData]);

  useEffect(() => {
    if (labelData?.seeScarLabelList?.labelList) {
      setLabelList(labelData.seeScarLabelList.labelList);
    }
  }, [labelData]);
  

  return (
    <ScarSearchPresenter
      language={language}
      detailSort={detailSort}
      setDetailSort={setDetailSort}
      sortType={sortType}
      handleSortType={handleSortType}
      handleSortRefresh={handleSortRefresh}
      scarList={scarList}
      labelList={labelList}
      scarOrder={state.scarOrder ? state.scarOrder : ''}
    />
  );
};

export default ScarSearchContainer;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ScarDetailPresenter from "./ScarDetailPresenter";
import { lightTheme } from "../../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import { SEE_SCAR_DETAIL } from "../../../graphql/Project/ScarDetail/query";
import { SCAR_APPROVAL } from "../../../graphql/Project/ScarDetail/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_MY_SIGN_PROJECT } from "../../../graphql/Project/ProjectList/query";
import { SEE_SCAR_LIST } from "../../../graphql/Project/ProjectDetail/query";

export const ScarDetailContext = createContext(null);

const ScarDetailContainer = () => {
  const { scarId } = useParams();
  localStorage.setItem("scar_id" , scarId);
  
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useContext(ThemeContext);
  const [processDotColor, setProcessDotColor] = useState(
    lightTheme.activeColor
  ); // 스카 상태 색상
  const [folding, setFolding] = useState({
    // 스카 정보 접기
    scar: false,
    action: false,
    history: false,
  });
  const [fullView, setFullView] = useState(false); // 이미지 전체 보기 모달
  const [notifyModal, setNotifyModal] = useState(false); // 알림 모달
  const [scarCheckModal, setScarCheckModal] = useState(false); // 발행자 스카 승인 모달
  const [scarActionModal, setScarActionModal] = useState(false); // 스카 승인 모달
  const [scarRejectModal, setScarRejectModal] = useState(false); // 스카 반려하기 모달
  const [scarInfo, setScarInfo] = useState(null);
  const [fullViewImages, setFullViewImages] = useState([]);
  const [projectPrivate, setProjectPrivate] = useState(null);

  const [scarApproval] = useMutation(SCAR_APPROVAL);
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  const {
    data: scarData,
    loading: scarLoading,
    refetch: scarRefetch,
  } = useQuery(SEE_SCAR_DETAIL, {
    variables: {
      scar_id: Number(scarId),
    },
    fetchPolicy: "network-only",
  });

  const handleFolding = useCallback(
    (name) => {
      // 정보 접기
      setFolding({
        ...folding,
        [name]: !folding[name],
      });
    },
    [folding]
  );

  const handleApproval = useCallback(
    async (signNumber) => {
      if (signNumber === 1) {
        setScarCheckModal(true);
        return;
      }
      // 조치자는 조치하기 모달
      if (signNumber === 2) {
        setScarActionModal(true);
        return;
      }

      // 기타 결재자는 alert
      if (
        window.confirm(
          (language === "Kor" && "승인 시 결재상태를 되돌릴 수 없습니다.") ||
            (language === "Eng" && "Approval status cannot be reversed.")
        )
      ) {
        try {
          const { data } = await scarApproval({
            variables: {
              scar_id: Number(scarId),
              approval: true,
              rejectText: "",
            },
          });
          if (data?.scarApproval?.result) {
            alert(
              (language === "Kor" && "승인되었습니다.") ||
                (language === "Eng" && "Approved")
            );
            await scarRefetch();
            await signLineRefetch();
            await scarListRefetch();
          }
          if (data?.scarApproval?.error !== "") {
            alert(data?.scarApproval?.error);
          }
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [language, scarId, scarData]
  );

  useEffect(() => {
    
    if (scarData?.seeScarDetail?.result) {      
      setScarInfo(scarData.seeScarDetail);
    }
  }, [scarData]);

  useEffect(() => {
    
    if (scarInfo) {            
      localStorage.setItem('prjId', scarInfo.scar.prj_id)
    }
  }, [scarInfo]);

  useEffect(() => {
    
    switch (scarData?.seeScarDetail?.scar?.scar_signStatus) {
      case "impossible": //조치불가
        setProcessDotColor(lightTheme.negativeColor);
        break;
      case "reject":
        setProcessDotColor(lightTheme.negativeColor);
        break;
      case "complete": //완료
        setProcessDotColor(lightTheme.subTextColor);
        break;
      default:
        setProcessDotColor(lightTheme.activeColor);
        break;
    }
  }, [scarData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const localStorageProjectIsOpen = localStorage.getItem("projectPrivate");

    if (localStorageProjectIsOpen !== null) {
      setProjectPrivate(localStorageProjectIsOpen);
    } else {
      setProjectPrivate(null);
    }
    return () => {
      localStorage.removeItem("projectPrivate");
    };
  }, []);

  return (
    <ScarDetailContext.Provider
      value={{
        // handleDeleteScar
        scarRefetch,
      }}
    >
      <ScarDetailPresenter
        navigate={navigate}
        language={language}
        processDotColor={processDotColor}
        folding={folding}
        handleFolding={handleFolding}
        fullView={fullView}
        setFullView={setFullView}
        notifyModal={notifyModal}
        setNotifyModal={setNotifyModal}
        scarCheckModal={scarCheckModal}
        setScarCheckModal={setScarCheckModal}
        scarActionModal={scarActionModal}
        setScarActionModal={setScarActionModal}
        scarRejectModal={scarRejectModal}
        setScarRejectModal={setScarRejectModal}
        handleApproval={handleApproval}
        scarInfo={scarInfo}
        setFullViewImages={setFullViewImages}
        fullViewImages={fullViewImages}
        scarLoading={scarLoading}
        projectPrivate={projectPrivate}
        memberList={location?.state?.memberList || []}
        isOwnScar={!!location?.state?.isOwnScar}        
      />
    </ScarDetailContext.Provider>
  );
};

export default ScarDetailContainer;

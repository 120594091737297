import React from "react";
import styled, {css} from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {
    HugeText,
    MediumText,
    SmallText,
} from "../../../components/share/StyledText";
import CustomCalendar from "../../../components/share/CustomCalendar";
import selectBottomArrow from "../../../assets/icons/share/selectBottomArrow.svg";
import refreshIcon from "../../../assets/icons/project/refreshIcon.svg";
import arrowTopIcon from "../../../assets/icons/project/arrowTopIcon.svg";
import StyledInput from "../../../components/share/StyledInput";
import ScarBriefInfoBox from "../../../components/feature/Project/ScarBriefInfoBox";
import {engLabelChanger} from "../../../lib/ProjectUtils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
`;
const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({theme}) => theme.bgColor};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) =>
          flexDirection ? flexDirection : "row"};
  align-items: ${({alignItems}) => (alignItems ? alignItems : "center")};
  justify-content: ${({justifyContent}) =>
          justifyContent ? justifyContent : "flex-start"};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const Sorting = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({theme}) => theme.subTextColor};

  svg {
    margin-right: 8px;
  }

  ${({detailSort, theme}) =>
          detailSort &&
          css`
            color: ${({theme}) => theme.defaultTextColor};

            path {
              fill: ${theme.blackColor};
            }
          `};
`;
const DetailSortingForm = styled.div`
  height: 0;
  padding: 0 16px;
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  opacity: 0;

  ${({detailSort}) =>
          detailSort &&
          css`
            height: 164px;
            padding: 25px 16px 0;
            margin-top: 18px;
            border: 1px solid ${({theme}) => theme.lightBorderColor};
            overflow: visible;
            opacity: 1;
          `};
`;
const Select = styled.select`
  width: 208px;
  height: 30px;
  font-size: 16px;
  color: ${({theme}) => theme.defaultTextColor};
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.lightBorderColor};
  background: url(${selectBottomArrow}) no-repeat 95% 50%;
`;
const RefreshBox = styled.div`
  width: 100%;
  height: 0;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({theme}) => theme.lightBorderColor};

  ${({detailSort}) =>
          detailSort &&
          css`
            height: 36px;
          `};
`;
const RefreshText = styled(SmallText)`
  margin: 0 26px 0 8px;
  color: ${({theme}) => theme.subTextColor};
`;

const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
`;

const ScarSearchPresenter = ({
                                 language = "Kor",
                                 detailSort,
                                 setDetailSort,
                                 sortType,
                                 handleSortType,
                                 handleSortRefresh,
                                 scarList,
                                 labelList,
                                 scarOrder
                             }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack
                    NO_MARGIN            
                    route={"/project-detail?&scarOrder="+scarOrder}
                />
                <FlexBox alignItems="flex-end" justifyContent="space-between">
                    <HugeText>
                        {language === "Kor" && "SCAR 검색하기"}
                        {language === "Eng" && "Search SCAR"}
                    </HugeText>
                    <Sorting
                        detailSort={detailSort}
                        onClick={() => setDetailSort(!detailSort)}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16">
                            <path
                                id="패스_3954"
                                d="M1171.708,1805.9h16l-5.913,4.87v7.652l-4.174,3.478v-11.13Z"
                                transform="translate(-1171.708 -1805.898)"
                                fill="#b5b5b5"
                            />
                        </svg>
                        {(language === "Kor" && "정렬") || (language === "Eng" && "Sort")}
                    </Sorting>
                </FlexBox>
                <DetailSortingForm detailSort={detailSort}>
                    <FlexBox>
                        <CustomCalendar value={sortType.period} onChange={handleSortType}/>
                    </FlexBox>
                    <FlexBox margin="16px 0 0">
                        <MediumText margin="0 16px 2px 0">
                            {" "}
                            {(language === "Kor" && "라벨") ||
                            (language === "Eng" && "Label")}
                        </MediumText>
                        <Select
                            name="label"
                            value={sortType.label}
                            onChange={(e) => handleSortType(e.target.name, e.target.value)}
                        >
                            <option value="total">
                                {" "}
                                {(language === "Kor" && "전체") ||
                                (language === "Eng" && "Total")}
                            </option>
                            {labelList?.map((label, index) => (
                                <option key={index} value={label?.lb_name}>
                                    {(language === "Kor" && label?.lb_name) ||
                                    (language === "Eng" && engLabelChanger(label?.lb_name))}
                                </option>
                            ))}
                        </Select>
                    </FlexBox>
                    <RefreshBox detailSort={detailSort}>
                        <FlexBox>
                            <Image src={refreshIcon}/>
                            <RefreshText onClick={handleSortRefresh}>
                                {(language === "Kor" && "초기화") ||
                                (language === "Eng" && "Reset")}
                            </RefreshText>
                            <Image
                                margin="4px 0 0"
                                src={arrowTopIcon}
                                onClick={() => setDetailSort(!detailSort)}
                            />
                        </FlexBox>
                    </RefreshBox>
                </DetailSortingForm>
                <StyledInput
                    margin="20px 0 30px"
                    name="searchTerm"
                    value={sortType.searchTerm}
                    onChange={(e) => handleSortType(e.target.name, e.target.value)}
                    placeholder={
                        (language === "Kor" && "검색할 SCAR 명을 입력해주세요.") ||
                        (language === "Eng" && "Enter the SCAR name")
                    }
                />
            </Header>
            {scarList?.map(scar => (
                <ScarBriefInfoBox
                    key={`scar-${scar.scar_id}`}
                    scar={scar}
                />
            ))}
        </Wrapper>
    );
};

export default React.memo(ScarSearchPresenter);

import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import FlexBox from "../../../components/share/FlexBox";
import {
  HugeText,
  LargeText,
  MediumText,
} from "../../../components/share/StyledText";
import GoBack from "../../../components/share/GoBack";
import StyledButton from "../../../components/share/StyledButton";
import { lightTheme } from "../../../styles/theme";
import dotsBlack from "../../../assets/icons/project/dotsBlack.svg";
import rightArrow from "../../../assets/icons/sideBar/sideBarArrow.svg";
import arrowTopIcon from "../../../assets/icons/project/arrowTopIcon.svg";
import fullViewIcon from "../../../assets/icons/project/fullViewIcon.svg";
// 퍼블용 이미지
import FullViewModal from "../../../components/feature/Project/FullViewModal";
import NotifyModal from "../../../components/feature/Project/NotifyModal";
import ScarActionModal from "../../../components/feature/Project/ScarActionModal";
import ScarRejectModal from "../../../components/feature/Project/ScarRejectModal";
import {
  engGradeChanger,
  engLabelChanger,
  gradeChanger,
  prjStatusChanger,
} from "../../../lib/ProjectUtils";
import { dateFormat } from "../../../utils/commons";
import emptyUser from "../../../assets/images/emptyUser.png";
import Loader from "../../../components/share/Loader";
import ImageFullViewButton from "../../../components/share/ImageFullViewButton";
import ScarCheckModal from "../../../components/feature/Project/ScarCheckModal";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "20px" : "0 20px 20px")};
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: ${({ theme }) => (theme.isApp ? 156 : 156)}px;
  padding: 20px 0;
  position: sticky;
  top: 0;
  left: -20px;
  z-index: 1;

  &:after {
    content: "";
    width: calc(100% + 40px);
    height: ${({ theme }) => (theme.isApp ? "calc(100% + 20px)" : "100%")};
    position: absolute;
    z-index: -1;
    top: ${({ theme }) => (theme.isApp ? -20 : 0)}px;
    left: -20px;
    background-color: ${({ theme }) => theme.lightBorderColor};
  }
`;
const HeaderFlexBox = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
`;
const ScarTitle = styled(HugeText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const UserName = styled(MediumText)`
  max-width: 154px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RankText = styled(MediumText)``;
const ScrollBox = styled.div`
  //height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`};
  height: 100vh;
  max-height: calc(932px - 156px);
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const ImageBox = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  margin: ${({ margin }) => (margin ? margin : 0)};
  transition: 0.4s;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `};
`;
const ProcessDot = styled.div`
  width: 12px;
  height: 12px;
  margin: 1px 6px 0 0;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
`;
const RowBox = styled(FlexBox)`
  padding: 16px 0;
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : 0)};
  border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;
const ProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;

  right: ${({ right }) => (right ? right : 0)}px;
`;
const CountBox = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.subTextColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.lightBorderColor};
`;
const FoldingBox = styled.div`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 800)}px;
  position: relative;
  transition: 0.3s ease-in-out;
  ${({ folding }) =>
    folding &&
    css`
      max-height: 0;
      overflow: hidden;
    `};
`;
const CloseButton = styled.div`
  color: ${({ theme }) => theme.subTextColor};
  display: flex;
  align-items: center;
`;
const DataBox = styled.div`
  margin-bottom: 20px;
`;
const Title = styled(MediumText)`
  color: ${({ theme }) => theme.subTextColor};
`;
const Info = styled(MediumText)`
  line-height: 1.2;
  word-break: break-all;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.defaultTextColor};
  margin-top: 10px;  
`;
const ScarImgBox = styled.div`
  width: ${({ width }) => (width ? width : "55px")};
  aspect-ratio: 1 / 1;
  margin: ${({ margin }) => (margin ? margin : "0 10px 0 0")};
  border-radius: 8px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
`;
const ScarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageRowBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0;

  & > * + * {
    margin: 0 16px;
  }
`;

const ImageFullView = styled.div`
  width: 55px;
  height: 55px;
  padding: 8px 0 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.activeColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.activeColor};
`;
const Line = styled.div`
  height: 1px;
  margin: ${({ margin }) => (margin ? margin : 0)};
  background-color: ${({ theme }) => theme.lightBorderColor};
`;
const HistoryBox = styled(FlexBox)`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;
const HistoryInfo = styled(FlexBox)`
  flex-direction: column;
  margin-left: 4px;
  flex: 1;
`;
const HistoryText = styled(LargeText)`
  width: 100%;
  line-height: 1.2;
`;
const TimeText = styled(MediumText)`
  margin-top: 6px;
  color: ${({ theme }) => theme.subTextColor};
`;

const ScarDetailPresenter = ({
  navigate,
  language = "Kor",
  processDotColor,
  folding,
  handleFolding,
  fullView,
  setFullView,
  notifyModal,
  setNotifyModal,
  scarCheckModal,
  setScarCheckModal,
  scarActionModal,
  setScarActionModal,
  scarRejectModal,
  setScarRejectModal,
  handleApproval,
  scarInfo,
  setFullViewImages,
  fullViewImages,  
  scarLoading,
  projectPrivate,  
  memberList = [],
  isOwnScar = false  
}) => {
  if (scarLoading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
    
  
  return (
    <Wrapper>
      <Header>
        <HeaderFlexBox>
          <GoBack
            NO_MARGIN            
            route={"/project-detail"}
          />
          <ImageBox
            onClick={() => {
              setNotifyModal(true);
              document.body.style.overflow = "hidden";
            }}
          >
            <Image src={dotsBlack} />
          </ImageBox>
        </HeaderFlexBox>
        <ScarTitle margin="16px 0 20px">{scarInfo?.scar?.scar_title}</ScarTitle>
        <HeaderFlexBox>
          <MediumText>{scarInfo?.scar?.scar_number}</MediumText>
          <FlexBox alignItems="center" margin="0 0 2px">
            <ProcessDot bgColor={processDotColor} />
            {prjStatusChanger(scarInfo?.scar?.scar_signStatus, language)} :{" "}
            {scarInfo?.scar?.scar_signStatus === "reject" ||
            scarInfo?.scar?.scar_signNumber === 7 ? (
              <>
                {(language === "Kor" && "반려") ||
                  (language === "Eng" && "Reject")}
              </>
            ) : scarInfo?.scar?.scar_signNumber === 6 ? (
              <>
                {(language === "Kor" && "완료") ||
                  (language === "Eng" && "Complete")}
              </>
            ) : scarInfo?.scar?.scar_signNumber === 1 ? (
              <>
                {(language === "Kor" && "발행") ||
                  (language === "Eng" && "Issued")}
              </>
            ) : (
              <>
                {(language === "Kor" && "진행중") ||
                  (language === "Eng" && "In progress")}
              </>
            )}
          </FlexBox>
        </HeaderFlexBox>
      </Header>
      <ScrollBox>
        <RowBox>
          {scarInfo?.mySignTurn &&
            scarInfo?.scar?.scar_signNumber !== 6 &&
            scarInfo?.scar?.scar_signNumber !== 7 && (
              <>
                <StyledButton
                  title={
                    (language === "Kor" &&
                      (scarInfo?.scar?.scar_signNumber === 2
                        ? "조치사항 입력"
                        : "승인")) ||
                    (language === "Eng" &&
                      (scarInfo?.scar?.scar_signNumber === 2
                        ? "Enter action"
                        : "Approve"))
                  }
                  width="48%"
                  height={48}
                  onClick={() =>
                    handleApproval(scarInfo?.scar?.scar_signNumber)
                  }
                />
                <StyledButton
                  title={
                    (language === "Kor" && "반려") ||
                    (language === "Eng" && "Decline")
                  }
                  width="48%"
                  height={48}
                  fontColor={lightTheme.negativeColor}
                  border={`1px solid ${lightTheme.negativeColor}`}
                  bgColor={lightTheme.whiteColor}
                  onClick={() => setScarRejectModal(true)}
                />
              </>
            )}
        </RowBox>
        {scarInfo && 
        <RowBox
          margin="4px 0 26px"
          onClick={() =>
            navigate("/approval-process-view", {
              state: {
                signLine: scarInfo?.scar?.signLine,
                memberList,
                isOwnScar,
                scarStatus: scarInfo?.scar?.scar_signStatus
              },
            })
          }
        >
          <LargeText>
            {language === "Kor" && "결재라인"}
            {language === "Eng" && "Approval Process"}
          </LargeText>

          <FlexBox>
            {[
              scarInfo?.scar?.signLine?.create_img,
              scarInfo?.scar?.signLine?.action_img,
              scarInfo?.scar?.signLine?.check_img,
            ]?.map((user, index) => {
              return (
                <ProfileImage
                  src={user === "" ? emptyUser : user}
                  key={`${index}_img`}
                  right={(index === 0 && -12) || (index === 1 && -6)}
                />
              );
            })}
            <CountBox>+{3}</CountBox>
            <Image src={rightArrow} />
          </FlexBox>
        </RowBox>
        }
        <FlexBox justifyContent="space-between" margin="0 0 24px">
          <LargeText>
            {language === "Kor" && "SCAR 발행 정보"}
            {language === "Eng" && "Violation Observed"}
          </LargeText>
          <FlexBox alignItems="center">
            <ProfileImage
              right={4}
              src={
                scarInfo?.scar?.signLine?.create_img === ""
                  ? emptyUser
                  : scarInfo?.scar?.signLine?.create_img
              }
            />
            <FlexBox alignItems="center">
              <UserName>{scarInfo?.scar?.signLine?.create_name}</UserName>
              <RankText>{scarInfo?.scar?.signLine?.create_rank}</RankText>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <DataBox>
          <FlexBox justifyContent="space-between">
            <Title>
              {language === "Kor" && "위반사항"}
              {language === "Eng" && "Violation Description"}
            </Title>
            <CloseButton onClick={() => handleFolding("scar")}>
              {folding.scar
                ? (language === "Kor" && "열기") ||
                  (language === "Eng" && "Open")
                : (language === "Kor" && "접기") ||
                  (language === "Eng" && "Close")}
              <Image
                margin="0 0 0 6px"
                rotate={folding.scar ? "180deg" : 0}
                src={arrowTopIcon}
              />
            </CloseButton>
          </FlexBox>
          <Info>{scarInfo?.scar?.scar_title}</Info>
        </DataBox>

        <FoldingBox folding={folding.scar}>
          <DataBox>
            <Title>
              {language === "Kor" && "위반위치"}
              {language === "Eng" && "Location"}
            </Title>
            <Info>{scarInfo?.scar?.scar_spot}</Info>
          </DataBox>

          <DataBox>
            <Title>
              {language === "Kor" && "위험등급"}
              {language === "Eng" && "Risk Impact"}
            </Title>
            <Info>
              {(language === "Kor" &&
                gradeChanger(scarInfo?.scar?.scar_riskGrade)) ||
                (language === "Eng" &&
                  engGradeChanger(scarInfo?.scar?.scar_riskGrade)) + " "}
              :{" "}
              {scarInfo?.scar?.scar_riskGrade === "A" ? (
                <>
                  {language === "Kor" && "발행일 자정까지 조치"}
                  {language === "Eng" &&
                    "Action by midnight on the date of issue"}
                </>
              ) : scarInfo?.scar?.scar_riskGrade === "B" ? (
                <>
                  {language === "Kor" && "발행일 익일 자정까지 조치"}
                  {language === "Eng" &&
                    "Action by midnight the day after issue"}
                </>
              ) : (
                <>
                  {language === "Kor" && "발행일 4일 후 자정까지 조치"}
                  {language === "Eng" &&
                    "Action taken until midnight four days after the date of issue"}
                </>
              )}
            </Info>
            <Info>
              {language === "Kor" && "조치기한"}
              {language === "Eng" && "Due Date"} :{" "}
              {dateFormat(
                new Date(Number(scarInfo?.scar?.scar_endDate)),
                "yyyy.MM.dd"
              )}
            </Info>
          </DataBox>

          <DataBox>
            <Title>
              {language === "Kor" && "라벨"}
              {language === "Eng" && "Label"}
            </Title>
            <Info>
              {scarInfo?.scar?.scar_label.length === 0
                ? "-"
                : scarInfo?.scar?.scar_label?.map((label, index) => (
                    <span key={index}>
                      {(language === "Kor" && label?.sLbl_name) ||
                        (language === "Eng" &&
                          engLabelChanger(label?.sLbl_name))}
                    </span>
                  ))}
            </Info>
          </DataBox>

          <DataBox>
            <Title>
              {language === "Kor" && "리스크코드"}
              {language === "Eng" && "Risk Code"}
            </Title>
            <Info>
              {!!scarInfo?.scar?.scar_riskcode
                ? scarInfo?.scar?.scar_riskcode
                : "-"}
            </Info>
          </DataBox>

          <DataBox>
            <Title>
              {language === "Kor" && "사진"}
              {language === "Eng" && "Images"}
            </Title>
            <ImageRowBox>
              {scarInfo?.scar?.img?.length < 1 && "-"}
              {scarInfo?.scar?.img[0] && (
                <ScarImgBox width="calc(50% - 8px)" height="165px" margin="0">
                  <ScarImage src={scarInfo?.scar?.img[0]?.si_img} />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.img[1] && (
                <ScarImgBox width="calc(50% - 8px)" height="165px" margin="0">
                  <ScarImage src={scarInfo?.scar?.img[1]?.si_img} />
                </ScarImgBox>
              )}
            </ImageRowBox>
            <FlexBox>
              {scarInfo?.scar?.img[2] && (
                <ScarImgBox>
                  <ScarImage src={scarInfo?.scar?.img[2]?.si_img} />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.img[3] && (
                <ScarImgBox>
                  <ScarImage src={scarInfo?.scar?.img[3]?.si_img} />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.img[4] && (
                <ScarImgBox>
                  <ScarImage src={scarInfo?.scar?.img[4]?.si_img} />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.img?.length !== 0 && (
                <ImageFullViewButton
                  onClick={() => {
                    setFullView(true);
                    setFullViewImages(
                      scarInfo?.scar?.img?.map((img) => img?.si_img)
                    );
                  }}
                />
              )}
            </FlexBox>
          </DataBox>
        </FoldingBox>

        <Line margin="6px 0 26px" />

        <FlexBox justifyContent="space-between" margin="0 0 24px">
          <LargeText>
            {language === "Kor" && "발행 확인자 코멘트"}
            {language === "Eng" && "Resident Engineer (PM) Comment"}
          </LargeText>
        </FlexBox>
        <DataBox>
          <Info>
            {scarInfo?.scar?.signLine?.check_approvalContent === ""
              ? "-"
              : scarInfo?.scar?.signLine?.check_approvalContent}
          </Info>
        </DataBox>

        <Line margin="6px 0 26px" />

        <FlexBox justifyContent="space-between" margin="0 0 24px">
          <LargeText>
            {language === "Kor" && "조치사항정보"}
            {language === "Eng" && "Corrective Action"}
          </LargeText>
          <FlexBox alignItems="center">
            <ProfileImage
              right={4}
              src={
                !!scarInfo?.actionUserInfo?.user_profileImg
                  ? scarInfo?.actionUserInfo?.user_profileImg
                  : emptyUser
              }
              onError={(e) => (e.target.src = emptyUser)}
            />
            <FlexBox alignItems="center">
              <UserName>{scarInfo?.actionUserInfo?.user_name}&nbsp;</UserName>
              <RankText>{scarInfo?.actionUserInfo?.user_rank}</RankText>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <DataBox>
          <FlexBox justifyContent="space-between">
            <Title>
              {language === "Kor" && "조치사항"}
              {language === "Eng" && "Resolution Description"}
            </Title>
            <CloseButton onClick={() => handleFolding("action")}>
              {folding.action
                ? (language === "Kor" && "열기") ||
                  (language === "Eng" && "Open")
                : (language === "Kor" && "접기") ||
                  (language === "Eng" && "Close")}
              <Image
                margin="0 0 0 6px"
                rotate={folding.action ? "180deg" : 0}
                src={arrowTopIcon}
              />
            </CloseButton>
          </FlexBox>
          <Info>
            {scarInfo?.scar?.scarAction?.sa_text === ""
              ? "-"
              : scarInfo?.scar?.scarAction?.sa_text}
          </Info>
        </DataBox>
        <FoldingBox folding={folding.action} maxHeight={500}>
          <DataBox>
            <Title>
              {language === "Kor" && "완료시간 (소요시간)"}
              {language === "Eng" && "End Date"}
            </Title>
            <Info>
              {scarInfo?.scar?.scarAction?.sa_completeDate !== ""
                ? dateFormat(
                    new Date(scarInfo?.scar?.scarAction?.sa_completeDate),
                    "yyyy.MM.dd - hh:mm"
                  )
                : "-"}
            </Info>
          </DataBox>
          <DataBox>
            <Title>
              {language === "Kor" && "사진"}
              {language === "Eng" && "Resolution Images"}
            </Title>
            <ImageRowBox>
              {scarInfo?.scar?.scarAction?.scarActionImg?.length === 0 && "-"}
              {scarInfo?.scar?.scarAction?.scarActionImg[0] && (
                <ScarImgBox width="calc(50% - 8px)" height="165px" margin="0">
                  <ScarImage
                    src={scarInfo?.scar?.scarAction?.scarActionImg[0]?.sai_img}
                  />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.scarAction?.scarActionImg[1] && (
                <ScarImgBox width="calc(50% - 8px)" height="165px" margin="0">
                  <ScarImage
                    src={scarInfo?.scar?.scarAction?.scarActionImg[1]?.sai_img}
                  />
                </ScarImgBox>
              )}
            </ImageRowBox>
            <FlexBox>
              {scarInfo?.scar?.scarAction?.scarActionImg[2] && (
                <ScarImgBox>
                  <ScarImage
                    src={scarInfo?.scar?.scarAction?.scarActionImg[2]?.sai_img}
                  />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.scarAction?.scarActionImg[3] && (
                <ScarImgBox>
                  <ScarImage
                    src={scarInfo?.scar?.scarAction?.scarActionImg[3]?.sai_img}
                  />
                </ScarImgBox>
              )}
              {scarInfo?.scar?.scarAction?.scarActionImg[4] && (
                <ScarImgBox>
                  <ScarImage
                    src={scarInfo?.scar?.scarAction?.scarActionImg[4]?.sai_img}
                  />
                </ScarImgBox>
              )}
              {/* <ScarImage src={scarEmptyImg} /> */}
              {scarInfo?.scar?.scarAction?.scarActionImg?.length !== 0 && (
                <ImageFullView
                  onClick={() => {
                    setFullView(true);
                    setFullViewImages(
                      scarInfo?.scar?.scarAction?.scarActionImg?.map(
                        (img) => img?.sai_img
                      )
                    );
                  }}
                >
                  <Image src={fullViewIcon} />
                  {(language === "Kor" && "전체보기") ||
                    (language === "Eng" && "Full View")}
                </ImageFullView>
              )}
            </FlexBox>
          </DataBox>
        </FoldingBox>
        <Line margin="6px 0 26px" />

        <FlexBox justifyContent="space-between" margin="0 0 24px">
          <LargeText>
            {language === "Kor" && "SCAR 히스토리"}
            {language === "Eng" && "Activity Log"}
          </LargeText>
          <CloseButton onClick={() => handleFolding("history")}>
            {folding.history
              ? (language === "Kor" && "열기") || (language === "Eng" && "Open")
              : (language === "Kor" && "접기") ||
                (language === "Eng" && "Close")}
            <Image
              margin="0 0 0 6px"
              rotate={folding.history ? "180deg" : 0}
              src={arrowTopIcon}
            />
          </CloseButton>
        </FlexBox>
        <FoldingBox
          folding={folding.history}
          maxHeight={scarInfo?.scar?.history?.length * 100}
        >
          {scarInfo?.scar?.history?.map((history, index) => (
            <HistoryBox key={index}>
              <ProfileImage
                src={!!history.sh_userImg ? history.sh_userImg : emptyUser}
                onError={(e) => (e.target.src = emptyUser)}
              />
              <HistoryInfo>
                <HistoryText>{history?.sh_text}</HistoryText>
                <TimeText fontColor={lightTheme.subTextColor}>
                  {dateFormat(history?.sh_createdAt, "yyyy.MM.dd - hh:mm")}
                </TimeText>
              </HistoryInfo>
            </HistoryBox>
          ))}
        </FoldingBox>

        {fullView && (
          <FullViewModal
            fullView={fullView}
            setFullView={setFullView}
            images={fullViewImages}
          />
        )}
      </ScrollBox>
      <NotifyModal // 알림 모달
        SCAR
        scarImage={scarInfo?.scar?.img[0]}
        notifyModal={notifyModal}
        setNotifyModal={setNotifyModal}
        projectPrivate={projectPrivate}
      />

      <ScarCheckModal // 발행확인자 코멘트 모달
        scarCheckModal={scarCheckModal}
        setScarCheckModal={setScarCheckModal}
      />

      <ScarActionModal // SCAR 조치하기 모달
        scarActionModal={scarActionModal}
        setScarActionModal={setScarActionModal}
      />

      <ScarRejectModal // SCAR 반려하기 모달
        scarRejectModal={scarRejectModal}
        setScarRejectModal={setScarRejectModal}
      />
    </Wrapper>
  );
};

export default React.memo(ScarDetailPresenter);

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ProjectDetailPresenter from "./ProjectDetailPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  SEE_PROJECT_DETAIL,
  SEE_SCAR_LIST,
} from "../../../graphql/Project/ProjectDetail/query";
import { WITHDRAWAL_PROJECT } from "../../../graphql/Project/ProjectDetail/mutation";
import { SubScriptContext } from "../../../App";
import { scarSortChanger } from "../../../lib/ProjectUtils";
import { ProfileContext } from "../../../App";
import useModal from "../../../hooks/useModal";

export const ProjectDetailContext = createContext(null);

const ProjectDetailContainer = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchSort = sessionStorage.getItem("sort");
  const { myProfile } = useContext(ProfileContext);
  const prjId = Number(localStorage.getItem("prjId"));
  const { language } = useContext(ThemeContext);
  const { subScriptOn } = useContext(SubScriptContext);
  const [scarSortType, setScarSortType] = useState(
    searchSort?.length > 0
      ? searchSort
      : (language === "Kor" && "진행중") || (language === "Eng" && "inProgress")
  ); // SCAR 발행 목록 정렬
  const [notifyModal, setNotifyModal] = useState(false); // 프로젝트 알림 모달
  const [userProfileModal, setUserProfileModal] = useState(false); // 유저 프로필 모달
  const [prjInfo, setPrjInfo] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [scarInfo, setScarInfo] = useState(null);
  const [memberModalInfo, setMemberModalInfo] = useState(null);
  const { isOpen, handleModalOpen, handleModalClose } = useModal();
  const [scarOrder, setScarOrder] = useState(localStorage.getItem("scarOrder") ? localStorage.getItem("scarOrder") : 'scar_createdAt'); 
  const focusScarId = localStorage.getItem("scar_id") ? localStorage.getItem("scar_id") : '';

  const handleScarOrder = useCallback(
    (selectedScarOrder) => {
      setScarOrder(selectedScarOrder);
      localStorage.setItem("scar_id" , '');
      localStorage.setItem("scarOrder" , selectedScarOrder);
      handleModalClose();
    },
    [handleModalClose]
  );

  const {
    data: prjDetailData,
    loading: prjDetailLoading,
    refetch: prjDetailRefetch,
  } = useQuery(SEE_PROJECT_DETAIL, {
    variables: {
      prjId,
    },
    fetchPolicy: "network-only",
  });

  const { data: scarData, refetch: scarRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId: prjId,
      statusFilter: scarSortChanger(scarSortType),
      scarOrder,
    },
    fetchPolicy: "network-only",
  });

  const [withdrawalProject] = useMutation(WITHDRAWAL_PROJECT);

  const handleQuitProject = useCallback(async () => {
    if (
      !window.confirm(
        (language === "Kor" &&
          "프로젝트 목록에서 해당 프로젝트가 \n 삭제됩니다. 탈퇴하시겠어요?") ||
          (language === "Eng" &&
            "The project will be \n deleted from the project list. \n This action cannot be undone!")
      )
    )
      return;

    try {
      const { data } = await withdrawalProject({
        variables: {
          prjId: prjId,
        },
      });
      if (data?.withdrawalProject?.result) {
        language === "Kor"
          ? alert("프로젝트에서 탈퇴하였습니다.")
          : alert("Leaving the Project");
        navigate("/");
        setNotifyModal(false);
      }
    } catch (e) {
      alert(e.message);
    }
  }, []);

  useEffect(() => {
    if (prjDetailData?.seeProjectDetail?.result) {
      setPrjInfo(prjDetailData.seeProjectDetail.project);
      setMemberList(prjDetailData.seeProjectDetail.members);

      if (!prjDetailData?.seeProjectDetail?.project?.prj_openAvailable) {
        localStorage.setItem("projectPrivate", "private");
      }
    }
  }, [prjDetailData]);

  useEffect(() => {
    if (scarData?.seeScarList?.result) {
      setScarInfo(scarData.seeScarList);
    }
  }, [scarData]);

  useEffect(() => {
    
    if (scarInfo && focusScarId) {
      document.getElementById(focusScarId) ?.scrollIntoView({ behavior: "smooth" ,block: "center", inline: "nearest"});
    }
  }, [scarInfo , focusScarId]);



  useEffect(() => {
    // if (!prjId) {
    //   navigate("project");
    // }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // 알림이 오면 새로고침
    const refetch = async () => {
      await prjDetailRefetch();
      await scarRefetch();
    };
    if (subScriptOn) {
      refetch();
    }
  }, [subScriptOn]);

  return (
    <ProjectDetailContext.Provider
      value={{
        language,
        scarSortType,
        setScarSortType,
        notifyModal,
        setNotifyModal,
        handleQuitProject,
        userProfileModal,
        setUserProfileModal,
        prjInfo,
        memberList,
        memberModalInfo,
        setMemberModalInfo,
        prjDetailRefetch,
      }}
    >
      <ProjectDetailPresenter
        language={language}
        navigate={navigate}
        prjInfo={prjInfo}
        scarInfo={scarInfo}
        prjId={prjId}
        myProfile={myProfile}
        prjDetailLoading={prjDetailLoading}
        prjDetailData={prjDetailData}
        scarOrder={scarOrder}
        handleScarOrder={handleScarOrder}
        isOpen={isOpen}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
      />
    </ProjectDetailContext.Provider>
  );
};

export default ProjectDetailContainer;
